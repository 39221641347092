<template>
  <div class="text-center py-3">
    <h4 class="mb-3">
      Il prezzo della richiesta selezionata è <strong>{{entityPrice}}</strong>.
      <br class="mb-2" />
      Il tuo credito disponibile è pari a <strong>{{walletPrice}}</strong>.
    </h4>
    <div v-if="hasFunds">
      <p>
        Clicca Invia richiesta per finalizzare l’ordine.
        <br />
        Saranno sottratti <strong>{{entityPrice}}</strong> dal tuo wallet disponibile.
      </p>
    </div>
    <div v-else>
      <p>
        Il tuo credito disponibile non è sufficiente per finalizzare l’ordine.
        <br />
        Ricarica il tuo wallet
        <br />
      </p>
      <div v-if="walletProducts.length > 0">
        <b-row cols-md="2">
          <b-col
            v-for="walletProduct in walletProducts"
            :key="walletProduct.productId"
            class="mb-3"
          >
            <wallet-button
              :item="walletProduct"
              :buy-product="buyProduct"
              :price-denominator="priceDenominator"
              :disabled="invalidSubjectData"
              :subject-payment-id="subjectInvoiceId"
            />
          </b-col>
          <b-col>
            <b-button @click="toogleProduct">{{buttonLabel}}</b-button>
          </b-col>
        </b-row>
      </div>
      <p>
        Se vuoi la fattura, scegli a chi intestarla
      </p>
      <b-row cols-md="2">
        <b-col>
          <b-checkbox
            v-model="askInvoicePerson"
            @change="(value) => setInvoiceRequest(value, false)"
          >A me</b-checkbox>
        </b-col>
        <b-col>
          <b-checkbox
            v-model="askInvoiceCompany"
            @change="(value) => setInvoiceRequest(value, true)"
          >Società</b-checkbox>
        </b-col>
      </b-row>
      <b-row cols-md="12">
        <div v-if="askInvoicePerson">
          <form-invoice-person @invoice-person-data="getInvoiceData"/>
        </div>
        <div v-if="askInvoiceCompany">
          <form-invoice-company @invoice-company-data="getInvoiceData" :companies-invoice="companiesInvoice"/>
        </div>
      </b-row>
    </div>
    <modal-payment
      id="paymentWizardModal"
      :product="currentProduct"
      :subject-invoice-id="subjectInvoiceId"
      :subject-invoice-type="subjectInvoiceType"
    />

  </div>
</template>

<script>

import { formatCurrency } from '@/utils/formatterHelper';
import { isNotEmpty } from '@/utils/validators';
import ModalPayment from '@/components/recharge/ModalPayment.vue';

const WalletButton = () => import('@/components/userSections/WalletButton.vue');
const FormInvoicePerson = () => import('@/components/forms/FormInvoicePerson.vue');
const FormInvoiceCompany = () => import('@/components/forms/FormInvoiceCompany.vue');

export default {
  name: 'PaymentSection',
  components: {
    ModalPayment,
    FormInvoiceCompany,
    FormInvoicePerson,
    WalletButton,
  },
  props: {
    entity: {
      entityType: String,
    },
    requestPrice: {
      currency: String,
      amount: Number,
    },
    hasFunds: Boolean,
  },
  data() {
    return {
      askInvoicePerson: false,
      askInvoiceCompany: false,
      productShortList: true,
      invoiceSubjectData: null,
      invalidSubjectData: false,
      subjectInvoiceId: null,
      subjectInvoiceType: null,
      currentProduct: null,
      companiesInvoice: [],
    };
  },
  computed: {
    entityPrice() {
      // entity.price?
      // console.log('this.entity.price?', this.entity);
      // return `${this.requestPrice.amount} ${this.requestPrice.currency}`;
      return formatCurrency(this.requestPrice.amount, 'it', this.requestPrice.currency);
    },
    walletAmount() {
      return this.$store.getters['wallet/getWallet']?.amount.amount || 0;
    },
    walletCurrency() {
      return this.$store.getters['wallet/getWallet']?.amount.currency || '';
    },
    walletPrice() {
      return formatCurrency(this.walletAmount, 'it', this.walletCurrency);
    },
    walletProducts() {
      const products = this.$store.getters['wallet/getProductList'];
      return this.productShortList ? products.filter((item) => item.highlight) : products;
    },
    priceDenominator() {
      if (isNotEmpty(this.walletProducts)) {
        const ratio = this.walletProducts.map((item) => item.creditsPurchased.amount / item.price.amount);
        return Math.min(...ratio);
      }
      return 1;
    },
    buttonLabel() {
      return this.productShortList ? 'Mostra tutti prodotti' : 'Solo evidenziati';
    },
  },
  mounted() {
    this.loadCompaniesList();
  },
  methods: {
    loadCompaniesList() {
      try {
        this.$store.dispatch('subject/loadCompaniesInvoice')
          .then((companies) => {
            console.debug('companies', companies);
            this.companiesInvoice = companies || [];
          }).catch((error) => console.error('fetch company', error));
      } catch (error) {
        console.error('loadCompaniesList error', error);
      }
    },
    routeTo(path) {
      this.$router.push(path);
    },
    buyProduct(product) {
      if (this.askInvoicePerson || this.askInvoiceCompany) {
        if (this.invalidSubjectData) return;
        this.currentProduct = product;

        let saveData;
        if (this.askInvoicePerson) {
          saveData = this.$store.dispatch('subject/saveSubject', {
            person: this.invoiceSubjectData,
            disable: true,
          });
        } else if (this.askInvoiceCompany) {
          saveData = this.$store.dispatch('subject/saveSubject', {
            company: this.invoiceSubjectData,
            setInvoiceTo: true,
            disable: true,
          });
        } else {
          console.log('buyProduct - to implement');
          return;
        }

        saveData.then((response) => {
          if (this.askInvoicePerson) {
            this.subjectInvoiceType = 'person';
            this.subjectInvoiceId = response.data.data.person.personId;
          } else if (this.askInvoiceCompany) {
            this.subjectInvoiceType = 'company';
            this.subjectInvoiceId = response.data.data.company.companyId;
          } else {
            return;
          }

          this.$bvModal.show('paymentWizardModal');
        });
      } else {
        this.currentProduct = product;
        this.subjectInvoiceId = null;
        this.subjectInvoiceType = null;
        this.$bvModal.show('paymentWizardModal');
      }
    },
    setInvoiceRequest(value, isCompany) {
      console.log('setInvoiceRequest', value);
      if (isCompany) {
        this.askInvoicePerson = false;
      } else {
        this.askInvoiceCompany = false;
      }
      if (!this.askInvoicePerson && !this.askInvoiceCompany) {
        this.invalidSubjectData = false;
      }
    },
    toogleProduct() {
      this.productShortList = !this.productShortList;
    },
    getInvoiceData(event) {
      console.log('getInvoiceData', event);
      const { invoiceData, invalidForm } = event;
      this.invoiceSubjectData = invoiceData;
      this.invalidSubjectData = invalidForm;
    },
  },
};
</script>

<style scoped>

</style>
